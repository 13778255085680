<template>
  <div>
    <div @click="hideAllDropdown">
      <BreadCrumb
        title="Legal Research"
        subtitle="Legislation Search / Search Result / Summary"
      />
      <div class="main-summary-container">
        <div class="main-summary-left">
          <div class="main-navigation mt-4">
            <div class="d-flex align-items-center left" @click="goBack">
              <span class="material-symbols-rounded me-2">arrow_back</span>
              <p class="mb-0">Back</p>
            </div>
            <div v-if="activeTab === 'Summary'" id="export-btn" class="d-flex align-items-center right">
              <p class="mb-0">Export</p>
              <span class="material-symbols-rounded">keyboard_arrow_down</span>
            </div>
            <b-tooltip
              target="export-btn"
              triggers="click"
              placement="bottom"
              id="export_box"
              :custom-class="`DROPDOWN-MENU`"
            >
              <div style="padding: 10px">
                <div
                  v-for="(item, index) in dropdownItems"
                  :key="index"
                  class="dropdown-option"
                  style="display: flex; align-items: center"
                >
                  <!-- Replace the standard checkbox with the CheckBox component -->
                  <CheckBox :set="selectedExportOptions.includes(item)" @input="toggleExport($event, item)" />
                  <label :for="`option-${index}`" style="margin-left: 8px">{{
                    item
                  }}</label>
                </div>
                <div class="d-flex align-items-center justify-content-end">
                  <button @click="exportData" class="d-flex right">
                    <span class="material-symbols-rounded me-2"> ios_share </span>
                    <p class="mb-0">Export</p>
                  </button>
                </div>
              </div>
            </b-tooltip>
          </div>

          <div class="main-container d-flex mt-4">
            <div class="navigation">
              <div
                :class="['link', { active: activeTab === 'Summary' }]"
                @click="activeTab = 'Summary'"
              >
                <p class="mb-3">Summary</p>
              </div>
              <div
                :class="['link', { active: activeTab === 'Legislation' }]"
                @click="activeTab = 'Legislation'"
              >
                <p class="mb-3">Legislation</p>
              </div>
            </div>

            <template v-if="activeTab === 'Summary'">
              <article class="case-summary-content" v-if="pageloading">
                <pageloader />
              </article>
              <article class="case-summary-content" v-else>
                <h4 class="case-title">{{ DATA.name || "Title" }}</h4>
                <section class="case-info">
                  <h4 class="section-header">Case Info</h4>

                  <div class="info-card">
                    <h5 class="info-title">Case Title</h5>
                    <p class="info-content">{{ DATA.name || "Title" }}</p>
                  </div>
                  <div class="info-card">
                    <h5 class="info-title">Jurisdiction</h5>
                    <p class="info-content">{{ DATA.jurisdiction || "USA" }}</p>
                  </div>
                </section>

                <!-- Case Summary Section -->
                <section class="case-summary">
                  <header class="summary-header">
                    <h2 class="section-header">Summary</h2>
                    <button
                      class="copy-button"
                      aria-label="Copy summary"
                      @click="copyContent"
                    >
                      <span class="material-symbols-rounded copy-icon"
                        >content_copy</span
                      >
                      <span class="button-text">Copy</span>
                    </button>
                  </header>

                  <div class="summary-content">
                    <!-- Brief Summary -->
                    <template v-if="brief_summary">
                      <div class="d-flex">
                        <h5 class="summary-title">Brief Summary</h5>
                        <button
                          class="expand-button"
                          aria-label="Expand summary"
                        >
                          <!-- <span class="material-symbols-rounded expand-icon">text_to_speech</span> -->
                          <span
                            v-b-tooltip.hover.top="{ variant: 'secondary' }"
                            :title="isSpeaking ? 'Pause' : 'Audio Summary'"
                            @click="toggleSpeech(brief_summary)"
                            class="material-symbols-rounded expand-icon"
                          >
                            {{ isSpeaking ? "pause" : "text_to_speech" }}
                          </span>
                        </button>
                      </div>
                      <p class="summary-text">{{ brief_summary }}</p>
                    </template>

                    <!-- Key Points -->
                    <template v-if="key_points">
                      <h5 class="summary-title">Key Points</h5>
                      <ul class="key-points-list">
                        <li
                          class="key-point"
                          v-for="(point, index) in key_points"
                          :key="index"
                        >
                          <p>
                            {{ index + 1 }}.<span>{{ point }}</span>
                          </p>
                          <button
                            class="expand-button"
                            aria-label="Copy key point"
                          >
                            <!-- <span class="material-symbols-rounded expand-icon">text_to_speech</span> -->
                            <span
                              v-b-tooltip.hover.left="{ variant: 'secondary' }"
                              :title="
                                buttonStates[index].isSpeaking
                                  ? 'Pause'
                                  : 'Audio'
                              "
                              @click="toggleSpeechKeyPoints(point, index)"
                              class="material-symbols-rounded expand-icon"
                            >
                              {{
                                buttonStates[index].isSpeaking
                                  ? "pause"
                                  : "text_to_speech"
                              }}
                            </span>
                          </button>
                        </li>
                      </ul>
                    </template>
                  </div>
                </section>
              </article>
            </template>
            <template v-else-if="activeTab === 'Legislation'">
              <article class="case-summary-content" v-if="pageloading">
                <pageloader />
              </article>
              <article class="case-summary-content" v-else>
                <h4 class="case-title">{{ DATA.name ?? "" }}</h4>
                <p
                  v-if="DATA.full_content.type === 'html'"
                  class="m-2 border-bottom mb-3"
                  v-html="DATA.full_content.value"
                ></p>
                <iframe
                  v-else-if="DATA.full_content.type === 'pdf'"
                  class="border-bottom mb-3"
                  :src="DATA.full_content.value"
                  height="600px"
                  width="100%"
                  frameborder="0"
                ></iframe>
                <!-- Disclaimer -->
                <p class="font-10 text-muted">
                  NexLaw does not hold any copyright for the case content. All
                  content displayed remains the property of its original source.
                </p>
              </article>
            </template>
          </div>
        </div>
        <div class="main-summary-right">
          <LawBot :id="Number(id)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../../../components/misc/BreadCrumb.vue";
import pageloader from "./../Loader.vue";
import research from "../../../store/research";
import LawBot from "./LawBot.vue";
import CheckBox from "../../../components/input/CheckBox.vue";
import { ExportData } from "../../../store/utils";
export default {
  components: {
    BreadCrumb,
    pageloader,
    LawBot,
    CheckBox,
  },
  data() {
    return {
      id: this.$route.params.id,
      dropdownItems: ["PDF", "Document"],
      activeTab: "Summary",
      selectedExportOptions: [],

      pageloading: true,
      permissions: localStorage.permissions,
      NewText: "",
      loadAnswer: false,
      selectedTab: "caseDocument",
      chatMessages: [],
      default_questions: [
        { text: "What is the ratio decidendi of the case?", displayed: false },
        {
          text: "What are the relevant obiter dicta of the case?",
          displayed: false,
        },
        {
          text: "What is the key legal principle or rule that the court relied on in reaching its decision?",
          displayed: false,
        },
        {
          text: "Can you identify the specific reasoning provided by the court that led to the judgment in this case?",
          displayed: false,
        },
        {
          text: "How does the court's decision in this case impact future cases and legal interpretations?",
          displayed: false,
        },
        {
          text: "What factors influenced the court's interpretation of the law and application to the facts of this case?",
          displayed: false,
        },
        {
          text: "Are there any dissenting opinions or conflicting views among the judges that need to be considered?",
          displayed: false,
        },
        {
          text: "What implications does this judgment have for similar cases or legal issues in the future?",
          displayed: false,
        },
      ],
      decDate: "",
      caseID: 0,
      topic: "",
      precedentsLoader: 0,
      all_precedents: [],
      pageLoading: true,
      case_info: "",
      case_file: "",
      content_to_copy: "",
      content_to_export: "",
      content_to_export_without_ner: "<b>Judgement Info</b>",
      precedents: [],
      content: "",
      exports_items: [
        { name: "PDF", icon: "picture_as_pdf" },
        { name: "DOCUMENT", icon: "description" },
      ],
      status: false, // Checkbox status for "Export with NER Tags"
      pdf_status: [],
      checked: false,
      ner_list: [],
      entities: [],
      brief_summary: "",
      key_points: [],
      basicDetails: {},
      key_points_original: [],
      full_content: [],
      isSpeaking: false,
      utterance: null,
      buttonStates: [],
      colors: {
        LAWYER: "#7EDFFF",
        COURT: "#9ACD32",
        JUDGE: "#C6BFFF",
        PETITIONER: "#FF7E7E",
        RESPONDENT: "#FFA500",
        CASE_NUMBER: "#BCC2D8",
        GPE: "#87CEFA",
        DATE: "#C1FFC1",
        ORG: "#FFDD7F",
        STATUTE: "#F5DEB3",
        WITNESS: "#FFFF8F",
        PRECEDENT: "#CBE3E2",
        PROVISION: "#F4A460",
        OTHER_PERSON: "#CBCBCB",
      },
      full_form: {
        LAWYER: "lawyer",
        COURT: "court",
        JUDGE: "judge",
        PETITIONER: "petitioner",
        RESPONDENT: "respondent",
        CASE_NUMBER: "Case number",
        GPE: "Countries, cities, state",
        DATE: "date",
        ORG: "organisation",
        STATUTE: "statute",
        WITNESS: "witness",
        PRECEDENT: "precedent",
        PROVISION: "provision",
        OTHER_PERSON: "People names",
      },
      NerTagExplanation:
        "*NER = Natural Language Processing Technique that identifies and classifies named entities from the case",
      PleaseWaitText:
        "Please wait a moment while we process it. This may take some time depending on the size the file and your internet connection speed.",
      content_original: "",
      ner_final: [],

      // Annotations
      annotations: {},

      loop: false,
    };
  },
  methods: {
    hideAllDropdown() {
      this.$root.$emit("bv::hide::tooltip");
    },
    goBack() {
      this.$router.go(-1);
    },
    copyContent() {
      let contentToCopy = "";
      if (this.brief_summary) {
        contentToCopy += `Brief Summary:\n${this.brief_summary}\n\n`;
      }
      if (this.key_points && this.key_points.length > 0) {
        contentToCopy += "Key Points:\n";
        this.key_points.forEach((point, index) => {
          contentToCopy += `${index + 1}. ${point}\n`;
        });
      }
      navigator.clipboard
        .writeText(contentToCopy)
        .then(() => {
          this.$toast.success("Copied");
        })
        .catch((err) => {
          console.error("Failed to copy text:", err);
        });
    },
    toggleSpeech(text, index) {
      const target = index == null ? this : this.buttonStates[index];

      if (!target.isSpeaking) {
        if (this.utterance?.text ?? null === text) {
          try {
            speechSynthesis.resume();
            target.isSpeaking = true;
          } catch (error) {
            this.initializeUtterance(text, index);
            speechSynthesis.speak(this.utterance);
          }
        } else {
          speechSynthesis.cancel();
          target.isSpeaking = false;
          this.initializeUtterance(text, index);
          speechSynthesis.speak(this.utterance);
        }
      } else {
        speechSynthesis.pause();
        target.isSpeaking = false;

        this.utterance.onpause = () => {
          target.isSpeaking = false;
          this.utterance.onpause = null;
        };
      }
    },
    toggleSpeechKeyPoints(text, index) {
      var speechText = text;
      const currentButtonState = this.buttonStates[index];
      if (!currentButtonState.isSpeaking) {
        if (
          this.utterance &&
          this.utterance.text !== "" &&
          this.utterance.text === speechText
        ) {
          try {
            speechSynthesis.resume();
            currentButtonState.isSpeaking = true;
          } catch (error) {
            this.initializeUtteranceKeyPoints(speechText, index);
            speechSynthesis.speak(this.utterance);
          }
        } else {
          speechSynthesis.cancel();
          currentButtonState.isSpeaking = false;
          this.initializeUtteranceKeyPoints(speechText, index);
          speechSynthesis.speak(this.utterance);
        }
      } else {
        speechSynthesis.pause();
        currentButtonState.isSpeaking = false;

        this.utterance.onpause = () => {
          currentButtonState.isSpeaking = false;
          this.utterance.onpause = null;
        };
      }
    },
    initializeUtteranceKeyPoints(speechText, index) {
      const currentButtonState = this.buttonStates[index];

      this.utterance = new SpeechSynthesisUtterance(speechText);
      this.utterance.onstart = () => {
        currentButtonState.isSpeaking = true;
      };
      this.utterance.onend = () => {
        speechSynthesis.cancel();
        currentButtonState.isSpeaking = false;
      };
    },
    initializeUtterance(speechText, index = null) {
      const target = index == null ? this : this.buttonStates[index];

      this.utterance = new SpeechSynthesisUtterance(speechText);
      this.utterance.onstart = () => {
        target.isSpeaking = true;
      };
      this.utterance.onend = () => {
        speechSynthesis.cancel();
        target.isSpeaking = false;
      };
    },
    toggleExport(e, item) {
      if (e) {
        if (!this.selectedExportOptions.includes(item)) this.selectedExportOptions.push(item);
      } else if (!e) {
        const index = this.selectedExportOptions.indexOf(item);
        this.selectedExportOptions.splice(index, 1);
      }
    },
    exportData() {
      if (!this.selectedExportOptions.length) {
        this.$toast.warn('Kindly select a format to export');
        return;
      }

      let contentToExport = '';
      if (this.brief_summary) {
        contentToExport += `Brief Summary:\n${this.brief_summary}\n\n`;
      }
      if (this.key_points && this.key_points.length > 0) {
        contentToExport += "Key Points:\n";
        this.key_points.forEach((point, index) => {
          contentToExport += `${index + 1}. ${point}\n`;
        });
      }

      let status = ExportData(contentToExport.replaceAll('\n', '<br>'), [
        this.selectedExportOptions.find((item) => item === 'Document'),
        this.selectedExportOptions.find((item) => item === 'PDF')
      ]);

      if (status[0]) this.$toast.success('Successfully downloaded Document');
      if (status[1]) this.$toast.success('Successfully downloaded PDF');

      this.selectedExportOptions = [];
    }
  },
  computed: {
    formattedDate() {
      const dateString = this.DATA.date;
      if (!dateString) return { day: "", date: "" };
      const [year, month, day] = dateString.split("-");
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const monthName = months[parseInt(month, 10) - 1];
      return {
        day: day,
        date: `${monthName} ${year}`,
      };
    },
  },
  created() {
    const fetchCaseSummary = async () => {
      try {
        const response = await research.OpenSummary(this.$route.params.id);
        const DATA = response.data.data;

        // Check if DATA.values is non-empty
        if (Array.isArray(DATA.values) && DATA.values.length > 0) {
          this.DATA = DATA;
          this.pageloading = false;

          // Find and handle brief_summary
          const item = DATA.values.find((el) => el.key === "brief_summary");
          this.brief_summary = item?.value || null;

          // Find and handle key_points
          const keyPointsItem = DATA.values.find(
            (el) => el.key === "key_points"
          );
          if (keyPointsItem?.value) {
            try {
              this.key_points = JSON.parse(keyPointsItem.value);
            } catch (e) {
              this.key_points = keyPointsItem.value;
            }
          } else {
            this.key_points = [];
          }

          // Set buttonStates based on key_points
          this.buttonStates = this.key_points.map(() => ({
            isSpeaking: false,
          }));
        } else {
          console.log("DATA.values is empty. Refetching...");
          await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for 2 seconds before refetching
          fetchCaseSummary(); // Refetch the case summary
        }
      } catch (error) {
        console.error("Error fetching case summary:", error);
        this.pageloading = false;
      }
    };

    // Start fetching the case summary
    fetchCaseSummary();
  },
};
</script>

<style scoped>
.left {
  color: var(--primary);
  cursor: pointer;
}

.left p {
  font-weight: 500;
  font-size: 16px;
  color: var(--primary) !important;
}

.link p {
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
}

.link.active p {
  color: var(--primary) !important;
}

.right {
  cursor: pointer;
  background: var(--primary);
  color: #fff;
  height: 32px;
  padding: 4px 16px;
  gap: 8px;
  border-radius: 6px;
}

.right p {
  font-size: 16px;
  font-weight: 500;
  color: #fff !important;
}
.main-summary-container {
  display: flex;
  justify-content: space-between;
  .main-summary-left {
    width: 90%;
  }
  .main-summary-right {
    width: 10%;
  }
}
.main-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navigation {
  min-width: 15%;
}

.main-container {
  display: flex;
  align-items: flex-start;
}
</style>

<style scoped>
.case-summary-content {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  padding: 32px;
}

.case-title {
  color: #383a3e !important;
  font: 600 20px/28px Poppins, sans-serif !important;
}

.case-content {
  display: flex;
  margin-top: 32px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  text-align: center;
  padding: 0 80px 47px;
}

.case-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 150px;
  max-width: 100%;
}

.case-info {
  margin-top: 32px;
  width: 100%;
  max-width: 816px;
  font-family: Poppins, sans-serif !important;
}

.section-header {
  color: #383a3e !important;
  font: 600 20px/1.4 Poppins, sans-serif !important;
}

.info-card {
  margin-top: 16px;
  padding: 4px 0;
}

.info-title {
  color: #383a3e !important;
  font: 500 16px/28px Poppins, sans-serif !important;
}

.info-content {
  color: #86888d !important;
  font: 400 14px/24px Poppins, sans-serif !important;
  margin-top: 4px;
}

.case-image {
  width: 100%;
  margin-top: 16px;
  object-fit: contain;
  object-position: center;
}

.judgement-info {
  margin-top: 16px;
}

.judgement-details {
  display: flex;
  margin-top: 24px;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
}

.decision-date {
  border-radius: 8px;
  background: #fafbfc;
  padding: 18px 38px;
  text-align: center;
}

.date-day {
  font: 600 32px Poppins, sans-serif !important;
  color: #383a3e !important;
}

.date-month {
  font: 500 16px/28px Poppins, sans-serif !important;
  color: #383a3e !important;
}

.date-label {
  color: #86888d;
  font: 400 14px Poppins, sans-serif !important;
  margin-top: 8px;
}

.jurisdiction-details {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
}

.jurisdiction-card,
.court-card {
  border-radius: 8px;
  background: #fafbfc !important;
  padding: 8px;
}

.party-info {
  display: flex;
  margin-top: 16px;
  gap: 4px;
  flex-wrap: wrap;
}

.petitioner-info,
.respondent-info {
  width: 200px;
  padding: 4px 0;
}

.petitioner-case,
.respondent-claim {
  flex: 1;
  min-width: 240px;
}

.judgement-result {
  margin-top: 16px;
}

.case-summary {
  margin-top: 32px;
}

.summary-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.copy-button,
.expand-button {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: #fff;
  padding: 2px 8px;
  cursor: pointer;
}
.copy-button {
  border: 1px solid #d1d2d5;
}
.copy-icon,
.expand-icon {
  color: var(--primary);
}

.button-text {
  color: #000046 !important;
  font: 500 14px Poppins, sans-serif !important;
}

.summary-content {
  margin-top: 16px;
}

.summary-title {
  color: #383a3e !important;
  font: 500 16px/28px Poppins, sans-serif !important;
}

.summary-text {
  color: #86888d !important;
  font: 400 14px/24px Poppins, sans-serif !important;
  margin-top: 12px;
}

.key-points-list {
  list-style-type: none;
  padding: 0;
}

.key-point {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 12px;
}

.key-point p {
  flex: 1;
  color: #86888d !important;
  font: 400 14px/24px Poppins, sans-serif !important;
}

.highlight {
  color: #383a3e !important;
}

@media (max-width: 991px) {
  .case-summary-content {
    padding: 20px;
  }

  .judgement-details,
  .party-info {
    flex-direction: column;
  }

  .decision-date {
    padding: 18px;
  }
}
</style>

<style scoped>
.dropdown-modal {
  justify-content: center;
  border-radius: 8px;
  border: 1px solid var(--Neutral-Grey, #f2f3f3);
  background: var(--Neutral-White, #fff);
  box-shadow: 0 4px 10px 0 rgba(170, 171, 175, 0.6);
  align-self: flex-end;
  z-index: 10;
  display: flex;
  width: 239px;
  max-width: 100%;
  flex-direction: column;
  color: var(--Neutral-Black, #383a3e);
  white-space: nowrap;
  padding: 10px 8px 10px 0;
  font: 500 14px/1 Poppins, sans-serif;
}

.dropdown-option {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  justify-content: flex-start;
  padding: 12px 16px;
}

.checkbox {
  border-radius: 4px;
  border: 1px solid var(--Neutral-Black, #383a3e);
  align-self: stretch;
  display: flex;
  width: 24px;
  height: 24px;
  margin: auto 0;
  cursor: pointer;
}
#Header {
  background: #fff;
}
#export_box {
  width: 239px !important;
  height: 148px !important;
  left: -20px !important;
}
.DROPDOWN-MENU ::v-deep .tooltip-inner .dropdown-option span {
  margin-right: 0px !important;
}

.option-text {
  align-self: stretch;
  margin: auto 0;
}

.export-button-wrapper {
  display: flex;
  width: 100%;
  padding-top: 8px;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  color: var(--Neutral-White, #fff);
  justify-content: flex-end;
}

.export-button {
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--Primary-Blue, #0e4485);
  align-self: stretch;
  display: flex;
  gap: 4px;
  overflow: hidden;
  margin: auto 0;
  padding: 2px 8px;
  border: none;
  cursor: pointer;
}

.export-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 18px;
  align-self: stretch;
  margin: auto 0;
}

.export-text {
  align-self: stretch;
  margin: auto 0;
}

@media (max-width: 991px) {
  .dropdown-modal,
  .dropdown-option,
  .export-button-wrapper,
  .export-button {
    white-space: initial;
  }
}

.copy-button,
.expand-button {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: #fff;
  padding: 2px 8px;
  cursor: pointer;
}
.copy-icon,
.expand-icon {
  width: 18px;
  color: var(--primary);
}

.button-text {
  color: #000046 !important;
  font: 500 14px Poppins, sans-serif !important;
}

.summary-content {
  margin-top: 16px;
}
</style>
