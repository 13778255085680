<template>
  <label class="checkbox">
    <input type="checkbox" v-model="checked" :disabled="disabled"/>
    <span class="material-symbols-rounded">check</span>
  </label>
</template>

<script>
export default {
  props: {
    set: {},
    force: {},
    disabled: { default: false },
  },

  data() { return {
    checked: false,
  }},

  watch: {
    set(v) { this.checked = v; },

    checked(newValue) {
      if (newValue !== this.set) {
        if (this.force) this.checked = this.set;
        this.$emit("input", this.checked);
      }
    },
  },

  created() { this.checked = this.set ?? false; }
}
</script>

<style scoped>
.checkbox {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid var(--neutral-black);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  input { display: none; }

  span {
    width: 100%;
    height: 100%;
    font-size: 18px;
    background-color: var(--primary);
    color: white;
    display: none;
    user-select: none;
  }

  &:hover {
    background-color: var(--light-blue);
    border-color: var(--primary);
  }

  input:checked ~ span {
    display: block;
  }
}

.checkbox:has(input[disabled="disabled"]) {
  background-color: var(--light-grey);
  border-color: var(--mid-grey);
  cursor: default;

  &:hover {
    background-color: var(--light-grey);
    border-color: var(--mid-grey);
  }
}
</style>